<template>
  <div>
    <b-row class="row-deck">
      <!-- Latest Customers -->
      <b-col md="12">
        <base-block content-class="p-0">
          <div class="font-size-sm push mb-0">
            <div v-if="dataLoad" class="text-center">
              <b-spinner variant="primary" label="Loading..."></b-spinner>
            </div>
            <b-form v-if="!dataLoad" @submit.stop.prevent="onBaseSearchSubmit">
              <div class="form-group form-row">
                <div class="col-sm-12 col-md-3 mt-3">
                  <b-form-input size="md" class="form-control-alt border" id="keyword" name="keyword" placeholder="Cautare dupa cuvinte cheie" v-model="$v.searchForm.keyword.$model" @change="makeAttention"></b-form-input>
                </div>
                <div class="col-sm-12 col-md-2 mt-3">
                  <b-form-select size="lg" id="category" name="category" class="form-control-alt border" v-model="$v.searchForm.category.$model" :options="categories" plain @change="makeAttention"></b-form-select>
                </div>
                <div class="col-sm-12 col-md-2 mt-3">
                  <flat-pickr size="md" class="form-control form-control-alt border" id="fromDate" placeholder="Data crearii de la" v-model="$v.searchForm.fromDate.$model" @change="makeAttention"></flat-pickr>
                </div>
                <div class="col-sm-12 col-md-2 mt-3">
                  <flat-pickr size="md" class="form-control form-control-alt border" id="toDate" placeholder="Data crearii pana la" v-model="$v.searchForm.toDate.$model" @change="makeAttention"></flat-pickr>
                </div>
                <b-col class="col-sm-12 col-md-3 mt-3">
                  <b-button v-bind:class="{'animated wobble': searchClickAttention}" size="md" type="submit" variant="primary">
                    <small><i class="fa fa-fw fa-search mr-1"></i> Cautare</small>
                  </b-button>
                  <router-link v-if="search" class="btn btn-md btn-warning ml-2" to="/requested-missing-product/list" active-class="" v-on:click.native="onClearFilters">
                    <small><i class="fa fa-fw fa-times-circle mr-1"></i> Reseteaza</small>
                  </router-link>
                  <b-button size="md" type="submit" class="ml-2" variant="success" @click="print">
                    <small><i class="fa fa-fw fa-print mr-1"></i> Tipareste</small>
                  </b-button>
                </b-col>
              </div>
            </b-form>
          </div>
        </base-block>
      </b-col>
    </b-row>
  </div>
</template>
<style lang="scss">
// Flatpickr + Custom overrides
@import '~flatpickr/dist/flatpickr.css';
@import './src/assets/scss/vendor/flatpickr';
</style>

<script>
import {validationMixin} from "vuelidate";
import flatPickr from "vue-flatpickr-component";
import {fleshErrorMessage} from "../../../../error/server-error";
import {MAIN_CATEGORY_LIST} from "../../../../constants/product-graphql";
import {PRINT_FUTURE_ORDER} from "../../../../constants/future-order-graphql";

export default {
  name: "Search",
  components: {flatPickr},
  mixins: [validationMixin],
  props: {
    search: null
  },
  data () {
    return {
      dataLoad: false,
      searchClickAttention: null,
      categories: [],
      searchTerm: this.search,
      searchForm: {
        keyword: null,
        category: null,
        fromDate: null,
        toDate: null,
      },
    }
  },
  validations: {
    searchForm: {
      keyword: {},
      category: {},
      fromDate: {},
      toDate: {},
    }
  },
  methods: {
    onClearFilters() {
      this.categoryFilters = {};
      this.searchForm = {
        keyword: null,
        category: null,
        subControls: null,
        fromDate: null,
        toDate: null,
      };
    },
    onBaseSearchSubmit() {
      this.$v.searchForm.$touch();
      let path = "/requested-missing-product/list";
      if (this.searchForm.keyword || this.searchForm.fromDate || this.searchForm.category || this.searchForm.toDate) {
        path = "/requested-missing-product/list?search="+btoa(JSON.stringify(this.searchForm));
      }
      this.$router
          .push(path)
          .catch(error => {
            error = {};
            fleshErrorMessage(error, this);
          });
    },
    makeAttention() {
      this.searchClickAttention = 'animated wobble';
      setTimeout(() => this.searchClickAttention = null, 1200);
    },
    print() {
      this.$apollo.mutate({
        mutation: PRINT_FUTURE_ORDER,
        variables: {
          'keyword': this.getFilterValue('keyword'),
          'category': this.getFilterValue('category'),
          'from': this.getFilterValue('fromDate'),
          'to': this.getFilterValue('toDate'),
        }
      }).then((response) => {
        this.$print(response.data.createPrintFutureOrder.printFutureOrder.response);
      }).catch((error) => {
        fleshErrorMessage(error, this);
      })
    },
    getFilterValue(filter)
    {
      if ((this.search && this.search[filter])) {
        return this.search[filter];
      }
    }
  },
  apollo: {
    productMainCategories: {
      query: MAIN_CATEGORY_LIST,
      result(result) {
        this.dataLoad = true;
        let mainCategories = [];
        mainCategories.push({value: null, text: 'Filtreaza dupa categorie'});
        result.data.productMainCategories.forEach(function (item) {
          let tmp = {};
          tmp.label = item.name;
          tmp.options = [];
          item.subCategories.forEach(function (subCategory) {
            tmp.options.push({value: subCategory.id, text: subCategory.name})
          });
          mainCategories.push(tmp);
        });
        this.categories = mainCategories;
        this.dataLoad = false;
      }
    }
  },
  mounted() {
    if (this.search) {
      this.searchForm = this.search;
      this.dataLoad = false;
    } else {
      this.searchForm = {
        keyword: null,
        fromDate: null,
        category: null,
        toDate: null,
      };
      this.dataLoad = false;
    }
  }
}
</script>
import gql from 'graphql-tag';

export const FUTURE_ORDER_LIST = gql`
query FutureOrderList($first: Int, $after: String, $keyword: String, $category: String, $fromDate: String, $toDate: String) {
  futureOrders(
    first: $first, 
    after: $after, 
    filter_keyword: $keyword, 
    filter_category: $category,
    filter_fromDate: $fromDate,
    filter_toDate: $toDate
  ) {
    pageInfo {
      hasNextPage,
      hasPreviousPage
      endCursor
      startCursor
    }
    totalCount
    edges {
      node {
        id
        _id
        category {
          _id
          name
        }
        product
        type
        comment
        orderUntil
        status
        createdAt
        typeLabel
        statusLabel
      }
    }
  }
}
`

export const GET_DATA_FOR_ADD = gql`
query GetDataForAdd {
  productMainCategories {
    id
    name
    subCategories {
      _id
      name
    }
  }
  meUser {
    futureOrderData {
      statusChoices
      typeChoices
    }
  }
}
`

export const CREATE_FUTURE_ORDER = gql`
mutation CreateFutureOrder($categoryId: String!, $product: String!, $type: Int!, $status: Int!, $comment: String, $term: String)
{
  createCreateFutureOrder(input:{categoryId: $categoryId, product: $product, type: $type, status: $status, comment: $comment, term: $term}) {
    createFutureOrder {
      response
    }
  }
}
`

export const GET_DATA_FOR_EDIT = gql`
query GetDataForEdit($id: ID!) {
  productMainCategories {
    id
    name
    subCategories {
      _id
      name
    }
  }
  meUser {
    futureOrderData {
      statusChoices
      typeChoices
    }
  }
  futureOrder(id: $id) {
    id
    _id
    category {
      id
      _id
      name
    }
    product
    type
    status
    createdAt
    orderUntil
    comment
    createdAt
  }
}
`

export const EDIT_FUTURE_ORDER = gql`
mutation EditFutureOrder($futureOrderId: String!, $categoryId: String!, $product: String!, $type: Int!, $status: Int!, $comment: String, $term: String)
{
  createEditFutureOrder(input:{futureOrderId: $futureOrderId, categoryId: $categoryId, product: $product, type: $type, status: $status, comment: $comment, term: $term}) {
    editFutureOrder {
      response
    }
  }
}
`

export const DELETE_FUTURE_ORDER = gql`
mutation DeleteFutureOrder($futureOrderId: String!)
{
  createDeleteFutureOrder(input:{futureOrderId: $futureOrderId}) {
    deleteFutureOrder {
      response
    }
  }
}
`

export const PRINT_FUTURE_ORDER = gql`
mutation PrintFutureOrder($keyword: String, $category: String, $from: String, $to: String) {
  createPrintFutureOrder(input: {
    keyword: $keyword,
    category: $category,
    fromDate: $from,
    toDate: $to
  }) {
    printFutureOrder {
      response
    }
  }
}
`
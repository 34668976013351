import gql from "graphql-tag/src";

export const ALL_PRODUCT_LIST = gql`
query AllProductsList($first: Int, $after: String, $keyword: String, $category: String, $mainCategory: String, $property: String, $promotion: String){
  listProducts(first: $first, after: $after, filter_keyword: $keyword, filter_category: $category, filter_property: $property, filter_promotion: $promotion, filter_mainCategory: $mainCategory) {
    pageInfo {
      hasNextPage,
      hasPreviousPage
      endCursor
      startCursor
    }
    totalCount
    edges {
      cursor
      node {
        id
        _id
        name
        description
        code
        category {
            _id
          name
        }
        price
        priceModel {
            unitPrice
            price
            promotions {
                type
                label
                parameters
            }
        }
        qty
        amountInCart
        defaultImageThumbnail
      }
    }
  }
}
`

export const MAIN_CATEGORY_LIST = gql`
    query MainCategoryList {
        productMainCategories {
            id
            name
            subCategories {
                id
                name
            }
        }
    }
`

export const CATEGORY_LIST = gql`
    query CategoryList($mainCategory: String) {
        productCategories (filter_mainCategory: $mainCategory) {
            id
            name
        }
    }
`

export const CATEGORY_FILTER_LIST = gql`
query CategoryList ($id: ID!, $filter: String) {
  productCategory (id: $id) {
    filterObjects {
      items (filter_property: $filter) {
        attribute
        value
      }
    }
  }
}
`

export const PRODUCT_DETAIL = gql`
query ProductDetail ($id: ID!) {
  itemProduct(id: $id) {
    id
    _id
    name
    code
    description
    price
    category {
      name
    }
    priceModel {
        unitPrice
        price
        promotions {
            type
            label
            parameters
        }
    }
    qty
    amountInCart
    properties {
      attribute
      value
      label
    }
  }
}  
`;

<template>
  <div>
    <base-page-heading title="Produse solicitate" subtitle="Care nu sunt pe stoc">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item href="javascript:void(0)">Produse solicitate</b-breadcrumb-item>
          <b-breadcrumb-item active>Lista</b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>
    <div class="content">
      <base-block title="Lista de produse solicitate" v-bind:subtitle="'Numar de produse: '+productNumber">
        <Search v-bind:search="search" />
        <b-table-simple responsive bordered striped table-class="table-vcenter">
          <b-thead head-variant="dark">
            <b-tr>
              <b-th class="d-sm-none" style="width: 70%;">De comandat</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 7%;">Data</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 12%;">Categorie</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 17%;">Produs</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 8%;">Felul</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 23%">Mentiuni</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 11%;">Termen</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 9%;">Status</b-th>
              <b-th class="d-none d-sm-table-cell text-center" style="width: 13%;"></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-if="products.length <= 0 && loaded">
              <b-td class="d-md-none text-center">
                <b-alert variant="warning" show class="mt-3">
                  <p class="mb-0">
                    Nu am gasit nici un rezultat
                  </p>
                </b-alert>
              </b-td>
              <b-td colspan="8" class="d-none d-sm-table-cell text-center">
                <b-alert variant="warning" show class="mt-3">
                  <p class="mb-0">
                    Nu am gasit nici un rezultat
                  </p>
                </b-alert>
              </b-td>
            </b-tr>
            <b-tr v-for="product in products" :key="product.node._id">
              <b-td class="d-sm-none" style="width: 75%;">
                <b-row>
                  <b-col class="col-4 mt-2 text-left mt-1">
                    <div><i class="fa fa-calendar-alt"></i> <span class="text-dark font-weight-bolder">{{ getDateStr(product.node.createdAt) }}</span></div>
                  </b-col>
                  <b-col class="col-8 mt-2 text-left mt-1">
                    <div><i class="fa fa-angle-double-right"></i>  <span class="text-primary">{{ product.node.category.name }}</span></div>
                  </b-col>
                  <b-col class="col-6 mt-2 text-right">
                  </b-col>
                </b-row>
                <div v-if="product.node.orderUntil"><i class="fa fa-calendar-check"></i> <span>{{ getDateStr(product.node.orderUntil) }}</span></div>
                <div><i class="fa fa-angle-right"></i> <span class="text-info">{{ product.node.product }}</span></div>
                <div><i class="fa fa-align-left"></i> <span class="text-warning">{{ product.node.comment }}</span></div>
                <b-row>
                  <b-col class="col-6 mt-2 text-left mt-1">
                    <div><i class="fa fa-dot-circle"></i> <span class="text-success">{{ product.node.typeLabel }}</span></div>
                  </b-col>
                  <b-col class="col-6 mt-2 text-left mt-1">
                    <div><i class="fa fa-dot-circle"></i> <span class="text-dark font-weight-bolder">{{ product.node.statusLabel }}</span></div>
                  </b-col>
                  <b-col class="col-6 mt-2 text-right">
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-6 mt-2 text-left mt-1">
                    <router-link size="md" class="align-items-center justify-content-between btn btn-alt-primary btn-md" v-bind:to="getEditUrl(product.node.id)">
                      <i class="fa fa-fw fa-pencil-alt"></i> Editare
                    </router-link>
                  </b-col>
                  <b-col class="col-6 mt-2 text-right mt-1">
                    <b-button size="md" type="button" variant="danger" class="ml-2 align-items-center justify-content-between" @click="deleteFutureOrder(product.node._id, product.node.product)">
                      <i class="fa fa-fw fa-trash-alt"></i> Stergere
                    </b-button>
                  </b-col>
                </b-row>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-w600 font-size-sm">
                <span class="text-dark font-weight-bolder">{{ getDateStr(product.node.createdAt) }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm">
                <span class="text-primary">{{ product.node.category.name }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm">
                <span class="text-info">{{ product.node.product }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm">
                <span class="text-success">{{ product.node.typeLabel }}</span>
              </b-td>
              <b-td class="border-right-0 d-none d-sm-table-cell font-size-sm">
                <span class="text-warning">{{ product.node.comment }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-sm">
                <span>{{ product.node.orderUntil ? getDateStr(product.node.orderUntil) : '-' }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-w600 font-size-sm">
                <span class="text-dark font-weight-bolder">{{ product.node.statusLabel }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell text-center border-left-0">
                <router-link class="align-items-center justify-content-between btn btn-alt-primary btn-md" v-bind:to="getEditUrl(product.node.id)">
                  <i class="fa fa-fw fa-pencil-alt"></i>
                </router-link>
                <b-button size="md" type="button" variant="danger" class="ml-2 align-items-center justify-content-between" @click="deleteFutureOrder(product.node._id, product.node.product)">
                  <i class="fa fa-fw fa-trash-alt"></i>
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </base-block>
    </div>
    <!-- END Page Content -->
    <infinite-loading @infinite="loadMore" ref="infiniteLoading">
      <div slot="spinner"><b-spinner variant="primary" label="Loading..."></b-spinner></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import Search from "./partial/Search";
import {ITEM_NUMBER_PER_PAGE} from "../../../constants/settings";
import {fleshErrorMessage} from "../../../error/server-error";
import {DELETE_FUTURE_ORDER, FUTURE_ORDER_LIST} from "../../../constants/future-order-graphql";
import {formatDate} from "@/constants/helpers/common-helper";

export default {
  name: "FutureOrderList",
  components: {InfiniteLoading,Search},
  data () {
    return {
      search: (this.$route.query.search) ? JSON.parse(atob(this.$route.query.search)) : null,
      products: [],
      pageInfo: null,
      productNumber: 0,
      loaded: false,
    }
  },
  watch: {
    $route() {
      this.products = [];
      this.pageInfo = null;
      this.loaded = false;
      this.search = (this.$route.query.search) ? JSON.parse(atob(this.$route.query.search)) : null;
      this.$refs.infiniteLoading.stateChanger.reset();
      this.$refs.infiniteLoading.attemptLoad();
    }
  },
  methods: {
    loadMore ($state) {
      this.$apollo.query({
        query: FUTURE_ORDER_LIST,
        variables: {
          first: ITEM_NUMBER_PER_PAGE,
          after: (this.pageInfo) ? this.pageInfo.endCursor : null,
          keyword: this.getFilterValue('keyword'),
          category: this.getFilterValue('category'),
          fromDate: this.getFilterValue('fromDate'),
          toDate: this.getFilterValue('toDate'),
        },
        fetchPolicy: 'no-cache',
      }).then((result) => {
        this.pageInfo = result.data.futureOrders.pageInfo;
        this.products.push(...result.data.futureOrders.edges);
        this.pageInfo.hasNextPage ? $state.loaded() : $state.complete();
        this.loaded = true;
        this.productNumber = result.data.futureOrders.totalCount;
      }).catch((error) => {
        fleshErrorMessage(error, this);
      })
    },
    getDateStr(value) {
      return formatDate(new Date(value));
    },
    getEditUrl(id)
    {
      return {name: "editFutureOrder", params: {id: btoa(encodeURI(id))}, query: this.$route.query}
    },
    deleteFutureOrder(id, name) {
      this.$swal({
        icon: 'warning',
        title: 'Stergere solicitare',
        html: 'Esti sigur ca vrei sa stergi solicitarea produsului <strong>'+name+'</strong>',
        showCancelButton: true,
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-primary m-1',
          cancelButton: 'btn btn-secondary m-1'
        },
        confirmButtonText: 'Da!',
        cancelButtonText: 'Nu',
      }).then(result => {
        if (result.value) {
          this.$apollo.mutate({
            mutation: DELETE_FUTURE_ORDER,
            variables: {
              'futureOrderId': id
            }
          }).then(() => {
            this.flashMessage.info({message: "Solicitarea este stearsa cu succes!"});
            let $product = this.products.find(function(element) {
              return element.node._id === id;
            });
            if ($product) {
              this.products.splice(this.products.indexOf($product),1);
              --this.productNumber;
            }
          }).catch((error) => {
            fleshErrorMessage(error, this);
          })
        }
      })
    },
    getFilterValue(filter)
    {
      if ((this.search && this.search[filter])) {
        return this.search[filter];
      }
    }
  }
}
</script>